<template>
  <b-container>
    <b-row
      class="justify-content-center"
    >
      <b-col
        cols="12"
        md="10"
        lg="8"
        xl="6"
      >
        <div
          class="text-center my-5"
        >
          <!-- Перенос бонусов ещё не произошел -->
          <template
            v-if="!bonusesTransferCompleted"
          >
            <h1>Происходит перенос бонусов...</h1>
          </template>
          <!-- /Перенос бонусов ещё не произошел -->

          <!-- Перенос бонусов произошел -->
          <template
            v-else
          >
            <!-- Ошибка переноса бонусов -->
            <template
              v-if="error"
            >
              <b-alert show variant="danger">{{ error }}</b-alert>
            </template>
            <!-- /Ошибка переноса бонусов -->

            <!-- Перенос бонусов произошел успешно -->
            <template
              v-else
            >
              <h3
                class="mb-4"
              >
                Поздравляем. Вы зарегистрированы в новом Профи-Клубе!
              </h3>

              <b-card
                class="carts__item text-left w-100"
              >
                <div
                  class="d-flex flex-row flex-wrap justify-content-between align-items-center"
                >
                  <!-- Дата и время операции -->
                  <small
                    class="text-secondary"
                  >
                    {{ lastBonus.createdAt }}
                  </small>

                  <!-- Количество -->
                  <h5
                    class="mb-0"
                    :class="{
                      'text-primary': lastBonus.amount >= 0,
                      'text-danger': lastBonus.amount < 0,
                    }"
                  >
                    <template v-if="lastBonus.amount >= 0">+</template>{{ lastBonus.amount }}
                    <b-icon
                      icon="lightning-fill"
                      aria-hidden="true"
                      font-scale="1"
                      variant="warning"
                    />
                  </h5>

                  <!-- Описание -->
                  <div
                      v-if="lastBonus.description"
                      class="w-100 mt-2 small"
                  >
                    {{ lastBonus.description }}
                  </div>
                </div>
              </b-card>
            </template>
            <!-- Перенос бонусов произошел успешно -->

            <b-button
              variant="primary"
              to="/home"
            >
              На главную
            </b-button>
          </template>
          <!-- /Перенос бонусов произошел -->
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { postTransferFromOldClub } from '../api/profi_club/bonuses';

export default {
  name: 'RegistrationOldProfiClub',

  props: {
    userUuidOldProfiClub: {
      type: String,
      default: null,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    error: null,
    bonusesTransferCompleted: false,
    lastBonus: null,
  }),

  computed: {
    ...mapGetters({
      jwt: 'auth/jwt',
    }),

    forward() {
      return `/registration-old-club?userUuidOldProfiClub=${this.userUuidOldProfiClub}&userUuidOldProfiClub=${this.userUuidOldProfiClub}`;
    },
  },

  async mounted() {
    if (this.jwt) {
      // Переносим бонусы
      try {
        this.isLoading = true;
        const response = await postTransferFromOldClub(this.userUuidOldProfiClub);

        if (response.data && response.data.operations && response.data.operations.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.lastBonus = response.data.operations[0];
        }
      } catch (e) {
        this.error = e.response.data || 'Произошла ошибка переноса бонусов';
      } finally {
        this.bonusesTransferCompleted = true;
        this.isLoading = false;
      }
    }
  },
};
</script>
